<template>
  <!-- Card -->
  <div v-if="displayType === 'card'">
    <div class="flex items-center justify-between mb-4" v-if="hasTitle">
      <h5 class="text-xl lg:text-2xl font-semibold">{{ title }}</h5>
    </div>

    <div class="nurseResources row-card-cont vs-con-loading__container w-full" id="row-card-cont-loading">
      <div 
      v-for="resource in resources" 
      class="nurseResources-wrapper p-4 border border-solid mb-2" 
      :key="resource._id"
      >
        <div class="nurseResources-poster relative w-full mb-4">
          <img
            :class="`cursor-pointer thumbnail block mx-auto w-full ${isVideo(resource.data) ? 'object-cover':'object-contain py-3'}`"
            :src="thumbnailSrc(resource.data)" 
            @click="openTab(resource.data)" 
            />

          <button
            class="vs-button video-btn flex items-center justify-center space-x-4 absolute my-1 py-2 px-3 text-white font-medium border-0"
            @click="openTab(resource.data)"
            >
            <img 
            class="w-4 mr-1" 
            :src="openFileImage(resource.data)" 
            />
            {{ openFileTitle(resource) }}
          </button>
        </div>
        <div class="nurseResources-description">
          <vx-tooltip :text="resource.data.title[0].text">
            <h6 class="font-semibold text-lg text-black mb-1">{{ resource.data.title[0].text }}</h6>
          </vx-tooltip>
        </div>
      </div>
    </div>

    <div
      v-if="loadMore.show"
      class="nurseResources-views flex flex-col items-center justify-center space-y-2 w-full">
      <p>You've viewed {{ loadMore.totalViewed }} of {{  loadMore.totalResults }} resources</p>

      <vs-progress 
      class="w-2/12" 
      :percent="totalViewed" 
      color="rgb(132, 205, 184)">
      </vs-progress>

      <vs-button 
      :disabled="!loadMore.hasNextPage || loadMore.isFetching"
        class="load-more-btn border border-solid text-primary" 
        @click="$emit('fetchMore')" 
        type="border">Load more</vs-button>
    </div>
  </div>

  <!-- Carousel Card -->
  <div class="nurseResources w-full" v-else-if="displayType === 'carousel'">

    <div class="flex items-center justify-between mb-4">
      <h5 class="text-xl lg:text-2xl font-semibold">{{ title }}</h5>
      <div class="flex" v-if="!hideArrowButtons">
        <vs-button @click="slideNav('prev')" class="mr-2 nurseResources-arrow" color="primary" type="border"
          icon="arrow_back"></vs-button>
        <vs-button @click="slideNav('next')" color="primary" class="nurseResources-arrow" type="border"
          icon="arrow_forward"></vs-button>
      </div>
    </div>

    <swiper
      ref="swiperRef"
      class="row-card-cont"
      :options="carouselSettings"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
      :key="$vs.rtl"
    >
      <swiper-slide v-for="resource in resources" :key="resource._id" class="nurseResources-wrapper p-4 border border-solid mb-2">
        <div class="nurseResources-content flex flex-col items-start justify-start space-y-2">
          <div class="nurseResources-poster relative w-full mb-2">
            <img
              :class="`cursor-pointer thumbnail block mx-auto w-full ${isVideo(resource.data) ? 'object-cover':'object-contain py-3'} ${isFromFirstSection ? 'first-section-img' : ''}`"
              :src="thumbnailSrc(resource.data)" 
              @click="openTab(resource.data)" 
              />

            <button
              class="vs-button video-btn flex items-center justify-center space-x-4 absolute my-1 py-2 px-3 text-white font-medium border-0"
              @click="openTab(resource.data)"
              >
              <img 
              class="w-4 mr-1" 
              :src="openFileImage(resource.data)" 
              />
              {{ openFileTitle(resource) }}
            </button>
          </div>
          <vs-popup :active.sync="openPdfAndVideo" fullscreen button-close-hidden class="pdf-container">
            <vs-button
              color="success"
              type="flat"
              :radius="true"
              @click="closePdf"
              icon="arrow_back_ios"
              class="my-5 p-4 rounded-none"
            > Go back </vs-button>
            
            <iframe v-if="openPdfAndVideo" :src="embedOrFileUrl" width="100%" heigh="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" :title="resource.data.title[0].text "></iframe>
            
          </vs-popup>
          <div class="nurseResources-description">
            <vx-tooltip :text="resource.data.title[0].text">
              <h6 class="font-semibold text-lg text-black mb-1">{{ resource.data.title[0].text }}</h6>
            </vx-tooltip>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-scrollbar" slot="scrollbar"></div>
    </swiper>
  </div>
</template>

<script>
import moment from "moment";
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    resources: {
      type: Array,
      required: true,
      default: () => [],
    },
    displayType: {
      type: String,
      required: true,
      default: () => "carousel",
    },
    slide: {
      type: String,
      required: false
    },
    loadMore: {
      type: Object,
      required: false,
      default: () => ({
        hasNextPage: false,
        totalResults: 0,
        totalViewed: 0,
        isFetching: false,
        show: false
      })
    },
    carouselOptions: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isFromFirstSection: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hideArrowButtons: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  data() {
    return {
      openPdfAndVideo: false,
      embedOrFileUrl: "",
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 20,
        scrollbar: {
          el: '.swiper-scrollbar',
          hide: true
        },
        breakpoints: {
          768: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      },
    };
  },
  computed: {
    totalViewed() {
      const { totalResults, totalViewed } = this.loadMore
      return ((totalViewed / totalResults) * 100) || 0
    },
    settings() {
      return this.$store.state.resourceHub.settings;
    },
    carouselSettings() {
      return {
        ...this.swiperOptions,
        ...this.carouselOptions,
      }
    },
    hasTitle() {
      return this.title !== ''
    }
  },
  methods: {
    slideNav(to) {
      const swiperRef = this.$refs.swiperRef;
      if (to === 'prev') swiperRef.swiper.slidePrev(500)
      if (to === 'next') swiperRef.swiper.slideNext(500)
    },
    thumbnailSrc(data) {
      let src;

      if (this.isVideo(data) && Object.keys(data.cover_image).length) {
        src = data.cover_image.url
      }

      if (!this.isVideo(data)) {
        src = this.settings.document_icon
      }

      return src
    },
    formatDate(date, format = "Do MMM") {
      return moment(date).format(format);
    },
    openFileImage(data) {
      return this.isVideo(data) ? "/play.svg" : "/document.svg"
    },
    openFileTitle({ data, first_publication_date }) {
      return this.isVideo(data) ? `Video • ${this.formatDate(first_publication_date)}` : "Document";
    },
    isVideo(data) {
      return data.vimeo_youtube_link.length
    },
    openTab(data) {
      const iframeView = localStorage.getItem("iframe-view");
      let link;
      let target = "_blank"
      if (this.isVideo(data)) link = data.vimeo_youtube_link[0].text;
      else link = data.file_link.url;

      if(iframeView){
        if(this.isVideo(data)){
          //TODO: replace code once embedUrl is available in the resouce payload
          target = "_self"
        } else {
          this.embedOrFileUrl = data.file_link.url
          return this.openPdf()
        }
      }
      
      window.open(link, target);
    },
    fetchingResources(isFetching) {
      if (isFetching) {
        this.$vs.loading({
          container: "#row-card-cont-loading",
          scale: 0.6
        })
      } else {
        this.$vs.loading.close('#row-card-cont-loading > .con-vs-loading')
      }
    },
    openPdf() {
      this.openPdfAndVideo = true;
    },
    closePdf() {
      this.openPdfAndVideo = false; 
    }
  },
  watch: {
    "loadMore.isFetching": function (isFetching) {
      this.fetchingResources(isFetching)
    },
    'isFetching': function(isFetching) {
      this.fetchingResources(isFetching)
    }
  }
};
</script>

<style lang="scss">
.nurseResources {
  &-wrapper {
    border-color: #e3ddd7;
    border-radius: 0.8rem;
  }

  &-poster {
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;

    button {
      background: #939393 !important;
      border-radius: 10rem !important;
      font-family: inherit;
      left: 0.7rem;
      bottom: 0.5rem;
    }

    > img {
      height: 12rem;
      border-radius: 0.5rem;

      &.first-section-img {
        height: 20rem;
      }
    }
  }

  &-description {
    h6 {
      height: 2.6rem;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  &-content {
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
    }
  }

  &-views {
    button {
      border-color: #e3ddd7 !important;
    }
  }

  &-arrow {
    border: 1px solid rgba(227, 221, 215, 1);
    background: rgba(255, 255, 255, 1);

    .vs-icon {
      font-size: 1.5rem;
    }
  }
}
.pdf-container {
  .vs-popup{
    border-radius: 0;
    -webkit-animation: none;
    animation: none;
  }
  iframe{
    height: 100vh;
  }
}
</style>
